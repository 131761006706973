/**
 * Quality Care page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannervirtualcare from "../../components/bannervirtualcare"
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import Sectionctafooter from "../../components/sectionctafooter"
import Sectionh1content from "../../components/sectionh1content"
import Seo from "../../components/seo"
import Sliderdoctors from "../../components/sliderdoctors"
import Iconvcqualitymedicalspecialist from "../../images/icons/iconvcqualitymedicalspecialist.inline.svg"
import Iconvconlinedoctor from "../../images/icons/iconvconlinedoctor.inline.svg"
import Iconvcqualitymedicalcommunity from "../../images/icons/iconvcqualitymedicalcommunity.inline.svg"
import Shapeleafcommunity from "../../images/shapes/shapeleafcommunity.inline.svg"
import Shapepincommunity from "../../images/shapes/shapepincommunity.inline.svg"
import "../../styles/pages/quality-care.scss"

/* Page function declaration */
const QualitycarePage = ({ data }) => {

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageQualitycare

  //CTA Footer page Speak to a Provider
  var ctaFooterSpeak= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "speak"
  })

  return (
    <Layout className="quality-care">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider = "slick"
      />

      {/* Section Hero */}
      <Hero classNamePage="quality-care" colorHero="white" colorShape="green" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralQualityCare} classNameH1="quality-care white"  vc="true">
        <div className="content-quality-care">
          <div className="shape-quality-care">
            <div className="box-svg-pin left">
              <span className="pin"><Shapepincommunity /></span>
              <span className="icon"><Iconvcqualitymedicalspecialist /></span>
            </div>
            <div className="box-svg-leaf">
              <span className="pin"><Shapeleafcommunity /></span>
              <span className="icon"><Iconvconlinedoctor /></span>
            </div>
            <div className="box-svg-pin right">
              <span className="pin"><Shapepincommunity /></span>
              <span className="icon"><Iconvcqualitymedicalcommunity /></span>
            </div>
          </div>
          <div className="intro-quality-care" dangerouslySetInnerHTML={{ __html:dataAcf.contentGeneralQualityCare}} />
          <div className="separator"></div>
          <div className="second-quality-care" dangerouslySetInnerHTML={{ __html:dataAcf.contentSecondQualityCare}} />
        </div>
      </Sectionh1content >

      {/* Section Doctors */}
      <section className="section-doctors">
        <div className="content-doctors">
          <h2 className="title-level-1">{dataAcf.titleGetKnowUs}</h2>
          <div dangerouslySetInnerHTML={{ __html:dataAcf.contentGetKnowUs}} />
          {/* SLIDER DOCTORS */}
          <div className="wrap-doctors">
            <Sliderdoctors arrDoctor={data.doctorsVc} />
          </div>
        </div>

        <Bannervirtualcare
          titleBanner={dataAcf.bannerTitleJoin}
          contentBanner={dataAcf.bannerContentJoin}
          ctaLabelBanner={dataAcf.bannerCtaJoin.title}
          ctaLinkBanner={dataAcf.bannerCtaJoin.url}
          ctaExtLink="ctaExtLink"
          idCta="joinus-qualitycare"
        />
      </section>


      {/* Section CtaFooter */}
      {ctaFooterSpeak.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="speak" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegrey-vc" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "quality-care"}) {
      acfPageQualitycare {
        bannerContentJoin
        bannerCtaJoin {
          title
          url
        }
        bannerTitleJoin
        contentGeneralQualityCare
        contentGetKnowUs
        contentSecondQualityCare
        titleGeneralQualityCare
        titleGetKnowUs
      }
      seo {
        metaDesc
        title
      }
    }
    doctorsVc: allWpDoctor(sort: {order: DESC, fields: menuOrder}) {
      edges {
        node {
          databaseId
          title
          acfPostDoctor {
            descriptionDoctor
            pictureDoctor {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: FIXED, width: 110, height: 110)
                }
              }
            }
            titleDoctor
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default QualitycarePage



