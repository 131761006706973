/**
 * Const with params element
 * Banner virtual care component
 * Display a section with
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Ctabutton from "./ctabutton"
import "../styles/components/bannervirtualcare.scss"


/* Declaration function */
const Bannervirtualcare = ({ titleBanner, contentBanner, ctaLabelBanner, ctaLinkBanner, ctaExtLink, idCta}) => {

  var boolExtLink = false
  if(ctaExtLink) boolExtLink=true

  return (
    <div className="banner-virtualcare">
      <div className="content-banner">
        <StaticImage src="../images/icons/virtual_care_icon.png" alt="icon virtual care leafs banner" layout="fixed" width={45} />
        {titleBanner && <h2 className="title-level-1">{titleBanner}</h2>}
        <div dangerouslySetInnerHTML={{ __html:contentBanner}} />
        <Ctabutton idCta={idCta} ctaLabel={ctaLabelBanner} ctaLink={ctaLinkBanner} otherStyle="bluemarin-format cta-banner-virtualcare" extLink={boolExtLink} />
      </div>
    </div>
  )
}

/* Export function */
export default Bannervirtualcare
