/**
 * Const with params element
 * Card doctor component
 * Display a card with doctor's information
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Shapepincommunity from "../images/shapes/shapepincommunity.inline.svg"
import "../styles/components/carddoctor.scss"

/* Declaration function */
const Carddoctor = ( { idDoctor, nameDoctor, titleDoctor, descDoctor, pictureDoctor } ) => {

  return (
    <div className="card-doctor">
      <h3>{nameDoctor}</h3>
      <h4>{titleDoctor}</h4>
      <div className="picture-doctor">
        <Shapepincommunity className="svg-pin" />
        <GatsbyImage className="picture" image={pictureDoctor} alt={`picture ${nameDoctor}`} />
      </div>
      <div className="text-doctor" dangerouslySetInnerHTML={{ __html:descDoctor}} />
    </div>
  )
}

/* Export function */
export default Carddoctor
