/**
 * Const with params element
 * Slider doctors component
 * Display a slider of mulitple doctors with Slick
 */
/* Import section */
import React from "react"
import Slider from "react-slick"
import Carddoctor from "./carddoctor"
import "../styles/components/sliderdoctors.scss"


/* Declaration function */
const Sliderdoctors = ( { arrDoctor } ) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 2,  //change to 3 if the number of doctors > 3
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
          arrows: false,
          dots: true
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      {arrDoctor.edges.map( ({ node }, index )  => (
        <Carddoctor
          key={index}
          idDoctor={node.databaseId}
          nameDoctor={node.title}
          titleDoctor={node.acfPostDoctor.titleDoctor}
          descDoctor={node.acfPostDoctor.descriptionDoctor}
          pictureDoctor={node.acfPostDoctor.pictureDoctor.localFile.childImageSharp.gatsbyImageData}
        />
        )
      )}
    </Slider>
  );
}


/* Export function */
export default Sliderdoctors
